// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-sign-js": () => import("./../src/pages/bio-sign.js" /* webpackChunkName: "component---src-pages-bio-sign-js" */),
  "component---src-pages-devices-modal-js": () => import("./../src/pages/devices-modal.js" /* webpackChunkName: "component---src-pages-devices-modal-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-smart-hospital-js": () => import("./../src/pages/smart-hospital.js" /* webpackChunkName: "component---src-pages-smart-hospital-js" */),
  "component---src-pages-software-js": () => import("./../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */)
}

